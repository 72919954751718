<template>
  <window-default ref="window" :resolve="find" :proccessData="proccessData" :mock="mock" :props="props" v-slot="{data}"
                  @loaded="(obj) => model = obj" @save="save">
    <window-body class="wrapper-lg">
      <e-row mr>
        <e-col size="1">
          <erp-s-field
              label="ID:"
          >
            <erp-input v-model="model.id" size="2" readonly />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Nome:"
          >
            <global-pessoa-input v-model="model.pessoa" />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Etapa:"
          >
            <erp-select v-model="model.etapa" :options="etapas" />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Pagar Comissão?"
          >
            <erp-checkbox v-model="model.comissao" size="2" />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Valor da Comissão:"
          >
            <erp-input v-model="model.valor" size="2" v-money="money" />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Critérios:"
          >
            <table class="erp-table full-width">
              <tr>
                <td style="width: 30%">Indicação Mínimo</td>
                <td style="width: 30%">Indicação Máximo</td>
                <td style="width: 30%">Valor</td>
                <td style="width: 10%">-</td>
              </tr>
              <tr v-for="(c, i) in model.criterios" :key="i">
                <td style="width: 30%">
                  <erp-input v-model="c.minimo" size="2" type="number" />
                </td>
                <td style="width: 30%">
                  <erp-input v-model="c.maximo" size="2" type="number" />
                </td>
                <td style="width: 30%"><erp-input v-model="c.valor" size="2" v-money="money" /></td>
                <td style="width: 10%"><i class="fa fa-times cursor-pointer" @click="removeCriterio(c)" /></td>
              </tr>
            </table>
            <e-btn class="m-t" label="Adicionar critério" @click="addCriterio" />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Ativo:"
          >
            <erp-checkbox v-model="model.active" />
          </erp-s-field>
        </e-col>
      </e-row>
    </window-body>
  </window-default>
</template>

<script>
import {WindowBody, ErpSField, ErpInput, ErpCheckbox} from 'uloc-vue-plugin-erp'
import WindowDefault from '../../../../../reuse/window/Default'
import ERow from '../../../../layout/components/Row'
import ECol from '../../../../layout/components/Col'
import {
  find,
  newProdutividadeComissao as _new,
  updateProdutividadeComissao as update,
} from '../../../../../domain/processos/services/produtividadeComissoes'
import {listTipoEtapasFull} from '@/domain/processos/services/produtividade'
import save from '../../../helpers/save'
import {convertRealToMoney, REAL_BRL} from "@/utils/money";
import {datePtToEn} from "@/utils/date";
import {VMoney} from "v-money";
import GlobalPessoaInput from "components/pessoa/components/include/pessoa/PessoaInput.vue";
import ErpSelect from "@/plugins/uloc-erp/components/form/select";
import EBtn from "@/plugins/uloc-erp/components/button/EBtn";

let mock = {
  id: null,
  comissao: false,
  valor: 0,
  pessoa: null,
  etapa: null,
  criterios: [],
  active: true
}

export default {
  name: 'Window',
  components: {
    EBtn,
    ErpSelect, GlobalPessoaInput, ECol, ERow, WindowDefault, WindowBody, ErpSField, ErpInput, ErpCheckbox},
  props: ['props'],
  directives: {money: VMoney},
  data () {
    return {
      model: JSON.parse(JSON.stringify(mock)),
      money: REAL_BRL,
      etapasData: []
    }
  },
  mounted () {
    listTipoEtapasFull()
        .then(response => {
          this.etapasData = response.data.result
        })
        .catch(error => {
          this.alertApiError(error)
        })
  },
  computed: {
    find () {
      return find
    },
    mock () {
      return mock
    },
    etapas () {
      return this.etapasData.map(e => {
        return {
          ...e,
          label: e.nome,
          value: e.id
        }
      })
    }
  },
  methods: {
    update: update,
    new: _new,
    save: save,
    beforeSave (originalData) {
      let data = JSON.parse(JSON.stringify(originalData))
      const valores = ['valor']
      valores.map(key => {
        data[key] = convertRealToMoney(data[key], true)
        if (!data[key]) {
          data[key] = null
        }
      })
      if (data.pessoa && data.pessoa.id) {
        data.pessoa = data.pessoa.id
      }
      if (data.etapa && data.etapa.id) {
        data.etapa = data.etapa.id
      }
      data.criterios = JSON.stringify(data.criterios)
      return data
    },
    proccessData (data) {
      let _mock = JSON.parse(JSON.stringify(this.mock))
      data = Object.assign(_mock, data)
      if (data.etapa && data.etapa.id) {
        data.etapa = data.etapa.id
      }
      if (!data.criterios || !Array.isArray(data.criterios)) {
        data.criterios = []
      }
      return data
    },
    addCriterio () {
      this.model.criterios.push({
        minimo: 0,
        maximo: 0,
        valor: 0.00
      })
    },
    removeCriterio (c) {
      this.model.criterios.splice(this.model.criterios.indexOf(c), 1)
    }
  }
}
</script>
